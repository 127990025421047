@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;700&family=Marck+Script&display=swap');

html.webp > body > div > header > :nth-child(2) {
	background-image: url('./images/code.webp');
}

html.no-webp > body > div > header > :nth-child(2) {
	background-image: url('./images/code.jpg');
}

body {
	box-sizing: border-box;
  	margin: 0;
	background-color:rgb(16, 16, 16);
	font: 400 1.6rem/1.5 'Dosis', sans-serif;
	color: rgba(255, 255, 255, .75);
	overflow-x: hidden;
}

h1 {
	color: white;
	margin-bottom: 0;
	font: 6rem 'Marck Script', cursive;
}

@media (max-width: 1024px) {
	.react-responsive-modal-modal {
		width: 75%;
	}
}

@media(min-width: 1540px) {
	main {
		max-width: 110rem;
		margin: 0 auto;
	}
}
